<template>
    <div class="uer-blank"></div>
</template>
<script>
export default {
    mounted() {
        let query = this.$route.query
        setTimeout(() => {
            this.$router.push({
                path: '/user/jobDetail',
                query: query
            })
        }, 0) 
    },
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
</style>